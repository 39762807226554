// No nesting! - colors declarations can only be one level deep
// No variables - you can't reference the colors by var name (e.g. 'primary') within this file

const base = {
  background: '#FFFFFF',
  primary: '#C24A6C',
  secondary: '#63CBE3',
  light: '#FFFCF9'
};

const textColors = {
  text: '#303030',
  primaryNegative: '#FFFCF9'
  // textBold: 'red',
  // textItalic
  // headingBold: 'green',
  // headingItalic
  // labels: 'green',
};
export const colors = {
  // defaults
  ...base,
  ...textColors
};
