import React from 'react';

// External Components
import { Button, Image } from '@thepuzzlers/pieces';

//Assets
import closeIcon from 'assets/svg/nav-close.svg';

// Main Component
export const CloseButton = ({ ...props }) => {
  return (
    <Button variant="clear" {...props}>
      <Image src={closeIcon} />
    </Button>
  );
};
