// GSAP
import { gsap } from 'gsap';

export const revealNavigation = (elements) => {
  // Get elements to animate
  const [banner, navbar] = elements;
  const navbarElements = [...navbar.children];
  const logo = navbarElements[0];
  const linksElements = [...navbarElements[1].children];
  const buttonsElements = [...navbarElements[2].children];
  const menu = navbarElements[3];

  // Tween defaults
  gsap.defaults({ duration: 1, ease: 'Power3.easeOut' });

  // Timeline
  let tl = gsap.timeline();

  tl.fromTo(logo, { y: 60 }, { autoAlpha: 1, y: 0, delay: 2.4 })
    .fromTo(
      linksElements,
      { x: 20 },
      { autoAlpha: 1, x: 0, stagger: 0.2, delay: 0.8 }
    )
    .fromTo(
      buttonsElements,
      { x: 20 },
      { autoAlpha: 1, x: 0, stagger: 0.2 },
      4.8
    )
    .fromTo(
      menu,
      { x: 20 },
      { autoAlpha: 1, x: 0, stagger: 0.2, delay: 0.4 },
      '<'
    )
    .fromTo(banner, { yPercent: -100 }, { autoAlpha: 1, yPercent: 0 }, 5);

  // Kill timeline function
  const killAnimation = () => {
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

// Navigation Overlay animation variants

const transitionDefault = { type: 'tween', ease: [0.17, 0.67, 0.8, 1] };
const linksAndInfoDefault = {
  y: 50,
  opacity: 0,
  transition: { type: 'tween', duration: 0.2, ease: 'easeIn' }
};

export const revealOverlay = {
  open: { y: 0, transition: { duration: 0.5, ...transitionDefault } },
  closed: {
    y: '-100%',
    transition: { delay: 0.4, duration: 0.3, ease: 'easeIn' }
  }
};

export const staggerVariant = {
  open: {
    transition: { staggerChildren: 0.2, delayChildren: 0.3 }
  },
  closed: {
    transition: { staggerChildren: 0.1, staggerDirection: -1 }
  }
};

export const revealLinks = {
  open: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5, ...transitionDefault }
  },
  closed: {
    ...linksAndInfoDefault
  }
};

export const revealInfo = {
  open: {
    y: 0,
    opacity: 1,
    transition: { delay: 0.6, duration: 0.8, ...transitionDefault }
  },
  closed: {
    ...linksAndInfoDefault
  }
};

export const revealSmallDecoration = {
  open: {
    y: 0,
    x: 0,
    transition: {
      delay: 0.3,
      duration: 0.9,
      ...transitionDefault
    }
  },
  closed: {
    y: 300,
    x: -500,
    transition: { type: 'tween', duration: 0.2, ease: 'easeIn' }
  }
};

export const revealMediumDecoration = {
  open: {
    rotate: 0,
    y: 0,
    x: 0,
    transition: {
      delay: 0.3,
      duration: 0.8,
      ...transitionDefault
    }
  },
  closed: {
    rotate: -30,
    y: -50,
    x: 250,
    transition: { type: 'tween', duration: 0.2, ease: 'easeIn' }
  }
};

export const revealBigDecoration = {
  open: {
    rotate: 0,
    x: 0,
    transition: {
      delay: 0.3,
      duration: 0.8,
      ...transitionDefault
    }
  },
  closed: {
    rotate: -40,
    x: -100,
    transition: { type: 'tween', duration: 0.2, ease: 'easeIn' }
  }
};

// Hover on desktop link variant

export const showUnderline = {
  initial: {
    scaleX: 0
  },
  animate: {
    scaleX: 1,
    originX: 'left',
    transition: {
      type: 'tween',
      duration: 0.2,
      ease: 'easeInOut'
    }
  }
};
