import React from 'react';

// External Components
import { GridWrapper, GridItem } from '@thepuzzlers/pieces';

export const SectionSpacer = ({ bg, spacing }) => {
  return (
    <GridWrapper className="section-spacer" sx={{ bg: bg }}>
      <GridItem sx={{ mt: spacing }} />
    </GridWrapper>
  );
};
