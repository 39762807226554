module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-core/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-thepuzzlers-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/carolin/code/puzzlers/zweierlei-restaurant.de-v3/src/intl","languages":["de"],"locales":{"default":"de","translations":[]},"translatedPaths":[{"default":"privacy-policy","de":"datenschutz"},{"default":"legal","de":"impressum"}],"secondPath":null,"redirect":false,"wrapProvider":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
