import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { motion } from 'framer-motion';

export const StoryBubble = ({ sx, ...props }) => {
  return (
    <Box sx={sx} {...props}>
      <svg
        width="95"
        height="87"
        viewBox="0 0 95 87"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible">
        <motion.path
          d="M73.1217 32.2685C78.8802 33.2176 84.6387 35.4718 88.6344 39.7429C93.6878 45.0817 95.4506 52.7933 94.7455 60.149C93.5703 72.6063 84.4037 84.3517 72.1815 86.6059C69.1259 87.1991 65.9529 87.0804 63.1324 85.894C60.0769 84.5889 57.7264 82.2162 55.4935 79.7247C52.3205 76.2841 49.1473 72.6063 47.0319 68.3352C42.5662 59.5558 43.0362 48.5222 47.6196 39.8615C53.143 29.6584 62.5448 30.6075 73.1217 32.2685Z"
          fill="#63CBE3"
          // Animation values
          animate={{
            y: [-20, 0, -20],

            transition: {
              default: {
                type: 'tween',
                ease: 'easeInOut',
                duration: 8,
                loop: Infinity
              }
            }
          }}
          initial={{ y: 0 }}
        />
        <motion.path
          d="M32.8957 15.7776C33.4833 21.3537 29.2526 26.0994 25.2568 29.8959C22.4363 32.6246 19.0283 35.5906 15.1501 35.4719C12.6821 35.3533 10.4492 34.0483 8.56891 32.6246C4.92576 30.0145 1.40013 26.5739 0.342439 22.1842C-1.65542 14.354 5.39578 2.01534 13.5047 0.235732C21.0261 -1.66252 32.0731 8.30326 32.8957 15.7776Z"
          fill="#63CBE3"
          // Animation values
          animate={{
            y: [-10, 0, -10],

            transition: {
              default: {
                type: 'tween',
                ease: 'easeInOut',
                duration: 6,
                loop: Infinity
              }
            }
          }}
          initial={{ y: 0 }}
        />
        <motion.path
          d="M27.1122 54.0475C31.578 56.8949 33.2233 62.8269 32.4006 68.0471C31.6955 73.1486 28.4049 78.2502 23.469 79.5552C15.4776 81.8094 6.0759 74.8096 7.13359 66.6234C8.42632 57.6067 18.0631 48.1155 27.1122 54.0475Z"
          fill="#63CBE3"
          // Animation values
          animate={{
            y: [10, 0, 10],

            transition: {
              default: {
                type: 'tween',
                ease: 'easeInOut',
                duration: 8,
                loop: Infinity
              }
            }
          }}
          initial={{ y: 0 }}
        />
      </svg>
    </Box>
  );
};

// Header spirals for animation

export const SpiralOne = () => (
  <Box
    className="spiral-one"
    sx={{
      position: 'absolute',
      top: ['37%', '37%', '34%', '16%', '23%', '16%'],
      left: ['10%', '11%', '10%', '15%', '18%', '31%'],
      transform: [
        'scaleY(1.9) rotate(175deg)',
        'scaleY(1.5) rotate(175deg)',
        'scaleY(1.5) rotate(175deg)',
        'rotate(175deg)',
        'rotate(175deg)',
        'translateX(-25%) rotate(175deg)'
      ],
      width: ['56%', '52%', '53%', '46%', '44%', '42%']
    }}>
    <svg
      viewBox="0 0 508 323"
      overflow="visible"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none">
      <path
        d="M98.1489 137.387C108.723 175.997 147.493 206.082 184.061 178.002C220.628 149.923 205.649 57.7181 155.864 28.5783C47.9241 -34.601 -54.5883 108.163 35.5878 234.162C178.774 434.229 496.426 265.25 507 1"
        stroke="none"
        id="spiralPathOne"
      />
    </svg>
  </Box>
);

export const SpiralTwo = () => (
  <Box
    className="spiral-two"
    sx={{
      position: 'absolute',
      top: ['50%', '44%', '41%', '25%', '50%', '50%'],
      left: ['36%', '35%', '35%', '36%', '40%', '41%'],
      transform: [
        'translateY(-100%) scaleY(2)',
        'scaleY(1.5)',
        'scaleY(1.5)',
        'unset',
        'translateY(-50%)',
        'translateY(-50%)'
      ],
      width: ['53%', '52%', '53%', '46%', '42%', '38%']
    }}>
    <svg
      viewBox="0 0 508 323"
      overflow="visible"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none">
      <path
        d="M98.1489 137.387C108.723 175.997 147.493 206.082 184.061 178.002C220.628 149.923 205.649 57.7181 155.864 28.5783C47.9241 -34.601 -54.5883 108.163 35.5878 234.162C178.774 434.229 496.426 265.25 507 1"
        stroke="none"
        id="spiralPathTwo"
      />
    </svg>
  </Box>
);
