export const fontFamilies = {
  body: {
    normal: 'fabrikat_monoregular, sans-serif',
    medium: 'fabrikat_monomedium, sans-serif',
    bold: 'fabrikat_monobold, sans-serif'
  },
  primary: {
    normal: 'fabrikat_monobold, sans-serif'
  }
};
