import React from 'react';

// External Components
import {
  Overlay,
  GridWrapper,
  GridItem,
  Paragraph,
  Box
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Framer Motion
import { AnimatePresence } from 'framer-motion';

// Local Components
import { Vector } from 'components';

// Assets
import waterdropSmall from 'assets/navigation/waterdrop-elements-three.svg';
import waterdropMedium from 'assets/navigation/waterdrop-elements-two.svg';
import waterdropBig from 'assets/navigation/waterdrop-elements.svg';

// Animations
import {
  revealOverlay,
  staggerVariant,
  revealLinks,
  revealInfo,
  revealSmallDecoration,
  revealBigDecoration,
  revealMediumDecoration
} from './animations';

export const NavigationOverlay = ({
  isOpen,
  handleClose,
  links,
  information
}) => {
  return (
    // Markup
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <Overlay
          handleClose={handleClose}
          containerSx={{ zIndex: 10 }}
          shouldCloseOnBackdropClick
          shouldCloseOnEscapePress>
          <ContentWrapper>
            <Spacer position={0} />
            <WaterdropBig />
            <NavlinksWrapper data={links} handleClose={handleClose} />
            <WaterdropMedium />
            <InformationWrapper data={information} />
            <WaterdropSmall />
            <Spacer position={1} />
          </ContentWrapper>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <GridWrapper
    // Animation values
    animate="open"
    initial="closed"
    exit="closed"
    variants={revealOverlay}
    sx={{
      alignContent: 'start',
      bg: 'primary',
      height: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden'
    }}>
    {children}
  </GridWrapper>
);

const NavlinksWrapper = ({ data, handleClose }) => (
  <Box
    as="ul"
    className="navigation-overlay__navlinks-wrapper"
    // Animation value
    variants={staggerVariant}
    sx={{
      display: 'grid',
      gridColumn: ['2/span 10', '3/span 8', '7/span 12', '1/span 19'],
      gridRow: 2,
      gridTemplateColumns: [null, null, null, '30% 30% auto'],
      justifyContent: [null, null, null, 'space-between']
    }}>
    {data.map((item) => (
      <Box
        as="li"
        key={item.name}
        onClick={handleClose}
        sx={{
          overflow: 'hidden',

          // Every child after first
          ':not(:first-of-type)': {
            mt: ['9.06%', '9.39%', '9.21%', 0]
          },
          // Every child after the third
          ':nth-of-type(n+4)': {
            mt: [null, null, null, '11.05%']
          }
        }}>
        {/* This extra box is needed for the animation to work */}
        <Box
          // Animation value
          variants={revealLinks}>
          <NavigationLink
            to={item.to}
            variant="overlayNavigation"
            sx={{
              color: 'light',
              display: 'block',
              textAlign: ['center', 'center', 'center', 'left'],
              '&.active': {
                color: 'secondary'
              }
            }}>
            {item.name}
          </NavigationLink>
        </Box>
      </Box>
    ))}
  </Box>
);

const InformationWrapper = ({ data }) => (
  <Box
    className="navigation-overlay__information-wrapper"
    // Animation value
    variants={revealInfo}
    sx={{
      display: 'grid',
      gridColumn: ['3/span 8', '3/span 8', '8/span 10', '1/span 18'],
      mt: ['55.47%', '36.40%', '58.47%', '13.22%'],
      gridRow: 3,

      // layout change in phone landscape
      justifyContent: [null, null, null, 'space-between'],
      gridTemplateColumns: [null, null, null, '49% 49%'],
      gridTemplateRows: [null, null, null, 'auto auto'],
      gridAutoFlow: [null, null, null, 'column']
    }}>
    {data.map((item) => (
      <Paragraph
        key={item.text}
        type="p-300-100"
        variant="medium"
        dangerouslySetInnerHTML={{
          __html: item.text
        }}
        sx={{
          color: 'light',
          textAlign: ['center', 'center', 'center', 'left'],
          // Every child after first
          ':not(:first-of-type)': {
            mt: ['7.59%', '6.46%', '8.35%', 0]
          },
          // Childs in the second row at phone landscape
          ':nth-of-type(even)': {
            mt: [null, null, null, '4.76%']
          }
        }}
      />
    ))}
  </Box>
);

// Decorations

const WaterdropSmall = () => (
  <Vector
    className="waterdrop-decoration__small"
    src={waterdropSmall}
    alt={null}
    // Animation value
    variants={revealSmallDecoration}
    sx={{
      alignSelf: ['end', 'end', 'end', 'center'],
      gridColumn: ['1/span 1', '2/span 1', '4/span 2', '1/span 1'],
      gridRow: [3, 3, 3, 1],
      justifySelf: ['end', 'center', 'end', 'start'],
      mb: ['250%', '210%', '100%', 0],
      width: ['83%', '75%', '95%', '95%']
    }}
  />
);

const WaterdropMedium = () => (
  <Vector
    className="waterdrop-decoration__medium"
    src={waterdropMedium}
    alt={null}
    // Animation value
    variants={revealMediumDecoration}
    sx={{
      alignSelf: 'start',
      gridColumn: ['11/13', '11/13', '20/span 3', '8/span 1'],
      gridRow: 3,
      justifySelf: ['center', 'start', 'start', 'start'],
      mt: ['138%', '64.13%', '107.16%', '103.68%'],
      width: ['70%', '88%', '88%', '100%']
    }}
  />
);

const WaterdropBig = () => (
  <Vector
    className="waterdrop-decoration__big"
    src={waterdropBig}
    alt={null}
    variants={revealBigDecoration}
    sx={{
      gridColumn: ['1/span 3', '1/span 3', '1/span 5', '15/span 3'],
      gridRow: 1,
      m: ['0 0 0 -71%', '0 0 0 -71%', '0 0 0 -62%', '-70% 0 0 0'],
      width: ['100%', '100%', '100%', '83%']
    }}
  />
);

// Spacer

const Spacer = ({ position }) => {
  const styles = [
    { gridRow: 1, mt: ['50.48%', '39.36%', '36.06%', '12.64%'] },
    { gridRow: 4, mt: ['18.19%', '16.67%', '25.46%', '8.14%'] }
  ];

  return <GridItem className="spacer" sx={{ ...styles[position] }} />;
};
