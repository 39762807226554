import { useStaticQuery, graphql } from 'gatsby';

export function useFooterData() {
  const { data } = useStaticQuery(graphql`
    query {
      data: footerDataJson {
        links {
          title
          to
        }
        openingHours
        contacts
        logoLinks {
          thePuzzlers {
            href
          }
          boxenStop {
            href
          }
        }
      }
    }
  `);

  return data;
}
