import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// External Components
import { Box } from '@thepuzzlers/pieces';

// Animations
import { revealImage } from 'sections/animations';

export const ImageContainer = ({
  className,
  image,
  alt,
  sx,
  imageSx,
  imgStyle,
  coverBg,
  ...props
}) => {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const cover = wrapperRef.current.children[1];

    const killAnimation = revealImage(wrapperRef.current, cover);

    return killAnimation;
  }, []);

  return (
    <Box
      className={`image-container ${className}`}
      ref={wrapperRef}
      sx={{ position: 'relative', ...sx }}
      {...props}>
      <GatsbyImage
        image={getImage(image)}
        alt={alt}
        style={{ height: '100%', width: '100%', ...imageSx }}
        imgStyle={imgStyle}
      />
      <Box
        className="image-container__cover"
        sx={{
          bg: coverBg,
          height: '100%',
          position: 'absolute',
          inset: 0,
          width: '100%',
          zIndex: 1
        }}
      />
    </Box>
  );
};
