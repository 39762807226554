// external dependencies
import React from 'react';

// Pieces components
import { Section, FlexWrapper, Box, Paragraph } from '@thepuzzlers/pieces';

import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  Vector,
  ZweierleiLogo,
  ThePuzzlersLogo,
  BoxenStopLogo
} from 'components';

// Assets
import { useFooterData } from './useFooterData';
import redLine from 'assets/footer/redLine.svg';

export const Footer = () => {
  const { links, openingHours, contacts, logoLinks } = useFooterData();

  return (
    <Section id="footer" as="footer">
      <MainLogo />
      <PortraitLinks links={links} />
      <LandscapeLinks links={links} />
      <OpeningHoursAndContact openingHours={openingHours} contacts={contacts} />
      <PartnerLogosWrapper logoLinks={logoLinks} />
    </Section>
  );
};

// Elements
const MainLogo = () => (
  <ZweierleiLogo
    className="footer__logo"
    sx={{
      gridColumn: [
        '6 / span 2',
        '6 / span 2',
        '12 / span 2',
        '12 / span 2',
        '12 / span 2',
        '12 / span 2'
      ],
      justifySelf: 'center',
      width: ['60%', '41.04%', '84.27%', '65.71%', '63.22%', '60.80%']
    }}
  />
);

const PortraitLinks = ({ links }) => {
  return (
    <RedLinedContainer
      className="footer__small-screen-links"
      sx={{
        gridColumn: ['1/13', '1/13', '3 / span 20', null, null, null],
        mt: ['15.99%', '12.41%', '9.71%', null, null, null],
        display: ['flex', 'flex', 'flex', 'none', 'none', 'none']
      }}>
      <FlexWrapper
        sx={{
          width: ['83%', '83%', '79%', null, null, null],
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          my: ['12.08%', '8.34%', '6.30%', null, null, null]
        }}>
        {links.map((link) => (
          /**
           * The NavigationLink placed inside box because
           * it creates some link inside span
           * <span>
           *  <a></a>
           * </span>
           * and some link just a tag
           * <a></a>
           * so now it placed inside Box to prevent the styles breaks
           */
          <Box
            key={link.title}
            className="small-screen-links__item"
            sx={{
              width: ['39%', '33%', '33%', null, null, null],

              // ONLY FOR PHONE
              ':nth-of-type(n+3)': {
                mt: ['2.29em', 0, 0, null, null, null]
              },

              // START FROM PHONE XL
              ':nth-of-type(n+4)': {
                // add margin to all elements, except the first row
                mt: [null, '2.27em', '2.37em', null, null, null]
              },
              ':nth-of-type(3n + 2)': {
                // center middle footer links
                textAlign: [null, 'center', 'center', null, null, null]
              },
              ':nth-of-type(3n+3)': {
                // align last links of row to right
                textAlign: [null, 'right', 'right', null, null, null]
              }
            }}>
            <NavigationLink variant="footer" to={link.to}>
              {link.title}
            </NavigationLink>
          </Box>
        ))}
      </FlexWrapper>
    </RedLinedContainer>
  );
};

const LandscapeLinks = ({ links }) => {
  const leftLinks = links.slice(0, 3);
  const rightLinks = links.slice(3, 6);
  return (
    <>
      <LandscapeLinksItem
        links={leftLinks}
        sx={{
          gridColumn: [
            null,
            null,
            null,
            '1 / span 11',
            '1 / span 11',
            '2 / span 10'
          ],
          gridRow: 1
        }}
      />
      <LandscapeLinksItem
        links={rightLinks}
        sx={{
          gridColumn: [
            null,
            null,
            null,
            '14 / span 11',
            '14 / span 11',
            '14 / span 10'
          ]
        }}
      />
    </>
  );
};

const OpeningHoursAndContact = ({ openingHours, contacts }) => {
  return (
    <Box
      className="footer__address-and-contact-blocks"
      sx={{
        gridColumn: [
          '1/13',
          '3 / span 8',
          '6 / span 14',
          '7 / span 12',
          '8 / span 10',
          '9 / span 8'
        ],
        textAlign: 'center'
      }}>
      <Box
        sx={{
          mt: ['18.50%', '16.15%', '13.75%', '12.05%', '14.58%', '11.54%']
        }}>
        {openingHours.map((address) => (
          <FooterText
            key={address}
            className="address-text footer-text"
            text={address}
          />
        ))}
      </Box>
      <Box
        sx={{
          mt: ['8.78%', '12.62%', '10.31%', '8.52%', '10.21%', '10.10%']
        }}>
        {contacts.map((contact) => (
          <FooterText
            key={contact}
            className="contact-text footer-text"
            text={contact}
          />
        ))}
      </Box>
    </Box>
  );
};

const PartnerLogosWrapper = ({ logoLinks: { thePuzzlers, boxenStop } }) => {
  return (
    <FlexWrapper
      className="footer__logo-wrapper"
      sx={{
        gridColumn: [
          '2 / span 10',
          '2 / span 10',
          '5 / span 16',
          '7 / span 12',
          '6 / span 14',
          '7 / span 12'
        ],
        justifySelf: [null, null, null, null, 'center'],
        width: ['100%', '100%', '100%', '100%', '88%', '100%'],
        justifyContent: 'space-between',
        mt: ['23.78%', '14.95%', '11.98%', '11.18%', '12.43%', '10.76%'],
        alignItems: 'flex-end'
      }}>
      <ThePuzzlersLogo
        as="a"
        href={thePuzzlers.href}
        target="__blank"
        sx={{
          width: ['42.65%', '38.53%', '35.96%', '31.74%', '32.95%', '31.66%']
        }}
      />
      <BoxenStopLogo
        as="a"
        target="__blank"
        href={boxenStop.href}
        sx={{
          width: ['38.88%', '38.53%', '35.96%', '31.74%', '32.95%', '31.66%']
        }}
      />
    </FlexWrapper>
  );
};

// Reusable Components

const LandscapeLinksItem = ({ links, sx }) => (
  <RedLinedContainer
    className="footer__wide-screen-links"
    sx={{
      justifyContent: 'space-evenly',
      display: ['none', 'none', 'none', 'flex', 'flex', 'flex'],
      alignSelf: 'center',
      ...sx
    }}>
    {links.map((link) => (
      /**
       * The NavigationLink placed inside box because
       * it creates some link inside span
       * <span>
       *  <a></a>
       * </span>
       * and some link just a tag
       * <a></a>
       * so now it placed inside Box to prevent the styles breaks
       */
      <Box
        key={link.title}
        className="wide-screen-links__item"
        sx={{
          py: [null, null, null, '1.42em', '1.5em', '2.25em']
        }}>
        <NavigationLink variant="footer" to={link.to}>
          {link.title}
        </NavigationLink>
      </Box>
    ))}
  </RedLinedContainer>
);

const FooterText = ({ text, ...props }) => (
  <Paragraph
    type="p-300-100"
    sx={{
      ':not(:first-of-type)': {
        mt: ['0.86em', '1em', '0.87em', '0.83em', '0.87em', '0.87em']
      }
    }}
    dangerouslySetInnerHTML={{ __html: text }}
    {...props}
  />
);

const RedLinedContainer = ({ children, sx }) => {
  return (
    <FlexWrapper
      sx={{
        position: 'relative',
        justifyContent: 'center',
        ...sx
      }}>
      <Vector
        src={redLine}
        className="top-red-line"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        }}
      />
      {children}
      <Vector
        src={redLine}
        className="bottom-red-line"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      />
    </FlexWrapper>
  );
};
