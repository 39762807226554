import { useStaticQuery, graphql } from 'gatsby';

export function useNavigationData() {
  const { data } = useStaticQuery(graphql`
    query {
      data: navigationDataJson {
        links {
          name
          to
        }
        buttons {
          reservation {
            text
          }
          call {
            text
            href
          }
        }
        information {
          text
        }
      }
    }
  `);

  return data;
}
