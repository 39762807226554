import React from 'react';

// Local Components
import { Vector } from './Vector';

// Assets
import zweierleiLogo from 'assets/svg/zweierleiLogo.svg';
import thePuzzlersLogo from 'assets/svg/puzzlersLogo.svg';
import boxenStopLogo from 'assets/svg/boxenstopLogo.svg';

// Main Components
export const ZweierleiLogo = ({ ...props }) => (
  <Vector src={zweierleiLogo} alt="zweierlei-logo" {...props} />
);

export const ThePuzzlersLogo = ({ ...props }) => (
  <Vector src={thePuzzlersLogo} alt="the-puzzlerss-logo" {...props} />
);

export const BoxenStopLogo = ({ ...props }) => (
  <Vector src={boxenStopLogo} alt="boxenstop-logo" {...props} />
);
