// GSAP
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

export const revealImage = (trigger, element) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: 'top 70%'
    }
  });

  tl.to(element, { height: 0, ease: 'Power3.easeOut', duration: 0.8 });

  const killAnimation = () => {
    tl?.scrollTrigger?.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

export const vectorParallax = (element, y) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 0.5
    }
  });

  tl.to(element, {
    y: y,
    ease: 'none'
  });

  const killAnimation = () => {
    tl?.scrollTrigger?.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

export const revealHeadlineAndLine = (trigger, headline, line) => {
  gsap.defaults({ ease: 'Power3.easeOut', duration: 0.6 });

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger,
      start: 'top 90%'
    }
  });

  tl.from(headline, {
    x: '30%'
  }).from(
    line,
    {
      scaleX: 0,
      transformOrigin: 'left'
    },
    '+=0.4'
  );

  const killAnimation = () => {
    tl?.scrollTrigger?.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

export const revealTextLines = (text) => {
  let childSplit = new SplitText(text, {
    type: 'lines',
    linesClass: 'split-child'
  });

  let parentSplit = new SplitText(text, {
    type: 'lines',
    linesClass: 'split-parent'
  });

  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: text,
      start: 'top 80%'
    }
  });

  tl.from(childSplit.lines, {
    yPercent: 100,
    stagger: 0.3,
    duration: 0.8,
    ease: 'Power3.easeOut',
    // Remove the extra markup when it's done
    onComplete: () => {
      parentSplit.revert();
      childSplit.revert();
    }
  });

  const killAnimation = () => {
    tl?.scrollTrigger?.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};

export const buttonHoverVariant = {
  inital: {
    y: 0
  },
  hover: {
    y: -5,
    transition: {
      type: 'spring',
      ease: 'backInOut'
    }
  }
};
