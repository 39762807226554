import React from 'react';

// Animations Dependencies
import Lottie from 'lottie-react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const startLottie = (lottie) => {
  if (lottie?.current?.play) {
    lottie.current.playSegments(0, 1);
  }
};

export const stopLottie = (lottie) => {
  if (lottie?.current?.stop) {
    lottie.current.stop();
  }
};

export const animateLottie = (containerRef, triggerRef) => {
  ScrollTrigger.create({
    trigger: containerRef.current,
    start: 'top 80%',
    end: 'bottom center',
    onEnter: () => startLottie(triggerRef)
  });

  return () => {
    ScrollTrigger.kill(true);
  };
};

export const LottieContainer = ({
  containerRef: externalContainerRef = null,
  lottieRef: externalLottieRef = null,
  useExternalRefs = false,
  animationData,
  loop = true,
  autoplay = false,
  startWhenInView = true,
  sx
}) => {
  const lottieRef = React.useRef(null);
  const lottieContainerRef = React.useRef(null);

  React.useEffect(() => {
    if (startWhenInView) {
      const lottieAnimation = animateLottie(
        useExternalRefs ? externalContainerRef : lottieContainerRef,
        useExternalRefs ? externalLottieRef : lottieRef
      );

      return lottieAnimation;
    }
  }, []);

  return (
    <Box
      className="lottie-container"
      ref={useExternalRefs ? externalContainerRef : lottieContainerRef}
      sx={sx}>
      <Lottie
        lottieRef={useExternalRefs ? externalLottieRef : lottieRef}
        autoplay={autoplay}
        loop={loop}
        animationData={animationData}
      />
    </Box>
  );
};
