import React from 'react';
import PropTypes from 'prop-types';

import { CoreLayout } from 'gatsby-theme-thepuzzlers-core';
import { Box } from '@thepuzzlers/pieces';

// Sections
import { Footer, Navigation } from '../sections';

// Local Components
import { SectionSpacer } from 'components';

import 'gatsby-plugin-theme-ui/fonts/fontImports';

// GSAP
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Layout = ({ children, pageContext }) => {
  return (
    // CoreLayout includes PageContextProvider & Global component

    <>
      <CoreLayout pageContext={pageContext}>
        <Navigation />
        <Box as="main" sx={{ position: 'relative', overflowX: 'hidden' }}>
          {children}
        </Box>
        <Footer />
        <SectionSpacer
          spacing={['25.08%', '15.51%', '11.31%', '5.81%', '8.01%', '6.25%']}
        />
      </CoreLayout>
    </>
  );
};

Layout.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Layout;
