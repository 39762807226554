export const typographyConfig = {
  // h3, h4 and paragraph are left in for the legal pages - please don't remove
  h3: {
    fontSizes: [33.6, 28.8, 37.6, 37.6, 44],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  h4: {
    fontSizes: [19.5, 24.5, 25.5, 18, 26.5, 28.5],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  paragraph: {
    fontSizes: [14.97, 15.29, 16.27, 14.1, 15.78, 15.82],
    fontFamily: 'body',
    lineHeight: 1.75
  },
  'h1-500-135': {
    fontSizes: [24.33, 25.18, 38.29, 24.44, 37.12, 43.5],
    fontFamily: 'primary',
    lineHeight: 1.35
  },
  'h2-100-100': {
    fontSizes: [14.97, 16.19, 17.23, 15.04, 16.7, 19.77],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h2-300-100': {
    fontSizes: [22.46, 22.48, 26.8, 18.8, 24.13, 29.66],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h2-700-115': {
    fontSizes: [39.3, 43.17, 53.6, 39.47, 51.97, 63.28],
    fontFamily: 'primary',
    lineHeight: 1.15
  },
  'h3-100-100': {
    fontSizes: [16.84, 17.99, 19.14, 15.98, 15.78, 17.8],
    fontFamily: 'primary',
    lineHeight: 1
  },
  'h3-300-150': {
    fontSizes: [18.71, 22.48, 28.71, 20.68, 29.7, 35.59],
    fontFamily: 'primary',
    lineHeight: 1.5
  },
  'h3-500-150': {
    fontSizes: [20.59, 22.48, 28.71, 20.68, 29.7, 35.59],
    fontFamily: 'primary',
    lineHeight: 1.5
  },
  'h3-700-140': {
    fontSizes: [23.39, 24.28, 28.71, 23.5, 29.7, 43.5],
    fontFamily: 'primary',
    lineHeight: 1.4
  },
  'h4-500-160': {
    fontSizes: [14.97, 15.29, 17.23, 15.04, 16.7, 17.8],
    fontFamily: 'body',
    lineHeight: 1.6
  },
  'h4-700-125': {
    fontSizes: [18.71, 19.78, 18.19, 18.8, 17.63, 19.77],
    fontFamily: 'primary',
    lineHeight: 1.25
  },
  'p-300-100': {
    fontSizes: [13.1, 13.49, 15.31, 11.28, 14.85, 15.82],
    fontFamily: 'body',
    lineHeight: 1
  },
  'p-500-135': {
    fontSizes: [14.97, 15.29, 17.23, 14.1, 16.7, 17.8],
    fontFamily: 'body',
    lineHeight: 1.35
  },
  'p-500-175': {
    fontSizes: [14.97, 15.29, 17.23, 14.1, 16.7, 17.8],
    fontFamily: 'body',
    lineHeight: 1.75
  },
  'body-100-100': {
    fontSizes: [11.23, 10.79, 11.49, 11.28, 11.14, 11.86],
    fontFamily: 'body',
    lineHeight: 1,
    letterSpacing: '0.05em'
  },
  'body-500-115': {
    fontSizes: [13.1, 17.99, 16.27, 11.28, 11.14, 11.86],
    fontFamily: 'body',
    lineHeight: 1
  },

  // links
  navlink: {
    fontSizes: [22.46, 22.48, 26.8, 18.8, 12.99, 15.82],
    fontFamily: 'primary',
    lineHeight: 1,
    letterSpacing: '0.05em'
  },

  // buttons
  buttonPrimary: {
    fontSizes: [12.16, 11.69, 13.4, 12.22, 12.99, 14.83],
    fontFamily: 'primary',
    lineHeight: 1,
    letterSpacing: '0.05em'
  },
  buttonSecondary: {
    fontSizes: [14.97, 14.39, 15.31, 14.1, 12.99, 13.84],
    fontFamily: 'primary',
    lineHeight: 1,
    letterSpacing: '0.05em'
  },
  callButton: {
    fontSizes: [12.16, 13.49, 15.31, 12.22, 14.85, 15.82],
    fontFamily: 'primary',
    lineHeight: 1,
    letterSpacing: '0.05em'
  }
};
