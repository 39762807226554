import React from 'react';

// External components
import {
  GridWrapper,
  Button,
  Image,
  FlexWrapper,
  Paragraph,
  Box
} from '@thepuzzlers/pieces';
import { NavigationLink } from 'gatsby-theme-thepuzzlers-intl';

// Data
import { useNavigationData } from './useNavigationData';

// Local Components
import { Vector } from 'components';
import { NavigationOverlay } from './NavigationOverlay';

// Hooks
import { useMediaQuery } from 'hooks/useMediaQuery';

// Assets
import logo from 'assets/svg/logomark.svg';
import menu from 'assets/svg/menu.svg';
import close from 'assets/svg/nav-close.svg';
import line from 'assets/svg/line.svg';

// Animations
import { revealNavigation, showUnderline } from './animations';
import { buttonHoverVariant } from 'sections/animations';

export const Navigation = () => {
  const { links, buttons, information } = useNavigationData();
  const data = {
    links,
    information
  };

  const navRef = React.useRef(null);
  const [openOverlay, setOpenOverlay] = React.useState(false);

  const isLargeScreen = useMediaQuery('(min-width: 1000px)');

  const handleOpen = () => {
    setOpenOverlay(true);
  };

  const handleClose = () => {
    setOpenOverlay(false);
  };

  React.useEffect(() => {
    const elements = [...navRef.current.children];

    const killAnimation = revealNavigation(elements);

    return killAnimation;
  }, []);

  // Close navigation overlay if screen is bigger than tablet landscape
  React.useEffect(() => {
    if (isLargeScreen && openOverlay) {
      handleClose();
    }
  }, [isLargeScreen]);

  return (
    // Markup
    <nav ref={navRef}>
      <Banner data={information} />
      <NavBar>
        <Logo />
        <DesktopLinks data={links} />
        <ButtonsWrapper {...buttons} />
        <MenuButton
          isOpen={openOverlay}
          handleClick={openOverlay ? handleClose : handleOpen}
        />
      </NavBar>
      <NavigationOverlay
        isOpen={openOverlay}
        handleClose={handleClose}
        {...data}
      />
    </nav>
  );
};

// Elements

const NavBar = ({ children }) => (
  <GridWrapper
    className="navigation__navbar"
    sx={{
      alignItems: 'center',
      bg: 'background',
      py: ['3.76%', '3.88%', '2.12%', '1.74%', '1.50%', '1.56%']
    }}>
    {children}
  </GridWrapper>
);

const Logo = () => (
  <NavigationLink
    to="/"
    sx={{
      gridColumn: [
        '1/span 2',
        '1/span 1',
        '1/span 2',
        '1/span 2',
        '1/span 2',
        '1/span 2'
      ],
      width: ['64%', '84%', '83%', '62%', '52%', '45%'],
      '& > a': { display: 'flex' },
      // Animation value
      visibility: 'hidden'
    }}>
    <Image src={logo} alt="Zweierlei restaurant logo" />
  </NavigationLink>
);

const MenuButton = ({ isOpen, handleClick }) => (
  // This button is used for both navbar and overlay
  <Button
    className="nav__menu-button"
    variant="clear"
    onClick={handleClick}
    sx={{
      display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
      gridColumn: ['11/13', '12/13', '23/25', '23/25', null, null],
      justifySelf: 'end',
      position: 'relative',
      width: ['58%', '86%', '85%', '56%', null, null],
      zIndex: 11,
      // Animation value
      visibility: 'hidden'
    }}>
    <Image
      src={isOpen ? close : menu}
      alt={isOpen ? 'Close icon' : 'Menu icon'}
    />
  </Button>
);

const ButtonsWrapper = ({ reservation, call }) => {
  return (
    <FlexWrapper
      className="nav__buttons-wrapper"
      sx={{
        alignItem: 'center',
        gridColumnEnd: [11, 11, 22, 23, 25, 25],
        justifySelf: 'end',
        mr: [0, '-30%', '-70%', '20%', 0, 0]
      }}>
      <Button
        as="a"
        // onClick={handleOpenReservationModal}
        href="https://www.thefork.com/restaurant/zweierlei-r597835"
        target="__blank"
        variant="primary"
        // Animation values
        inital="initial"
        whileHover="hover"
        variants={buttonHoverVariant}
        sx={{
          // Animation value
          visibility: 'hidden'
        }}>
        {reservation.text}
      </Button>
      {/* We add the box because the NavigationLink can't be animated */}
      <Box
        sx={{
          display: ['none', 'flex', 'flex', 'flex', 'flex', 'flex'],
          ml: [null, '1.23em', '1.15em', '1.23em', '1.15em', '1.07em'],
          // Animation value
          visibility: 'hidden'
        }}
        // Animation values
        inital="initial"
        whileHover="hover"
        variants={buttonHoverVariant}>
        <NavigationLink
          to={call.href}
          variant="buttons.primary"
          sx={{ color: 'text', bg: 'secondary' }}>
          {call.text}
        </NavigationLink>
      </Box>
    </FlexWrapper>
  );
};

const DesktopLinks = ({ data }) => (
  <FlexWrapper
    as="ul"
    sx={{
      display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
      gridColumn: [null, null, null, null, '4/span 14', '8/span 12'],
      justifyContent: [null, null, null, null, 'flex-end', 'flex-start'],
      // Every child after first one
      '& > li:nth-of-type(n+2)': {
        ml: [null, null, null, null, '5.53%', '6.33%']
      }
    }}>
    {data.map(
      (item, index) =>
        // Don't show last link (reservation)
        index <= data.length - 2 && (
          <Box
            key={item.name}
            as="li"
            // Animation values
            initial="initial"
            whileHover="animate"
            sx={{
              position: 'relative', // Animation value
              visibility: 'hidden'
            }}>
            <NavigationLink to={item.to} variant="navigation">
              {item.name}
            </NavigationLink>
            <Vector
              src={line}
              alt={null}
              // Animation value
              variants={showUnderline}
              sx={{
                mx: 'auto',
                position: 'absolute',
                bottom: '-40%',
                height: [null, null, null, null, '0.1em', '0.3em'],
                left: 0,
                right: 0,
                width: [null, null, null, null, '2.5em', '3em']
              }}
            />
          </Box>
        )
    )}
  </FlexWrapper>
);

const Banner = ({ data }) => {
  // helper function
  const removeBr = (text) => {
    return text.replace('<br/>', ' ');
  };
  return (
    <GridWrapper
      className="navigation__banner"
      sx={{
        bg: 'primary',
        display: ['none', 'none', 'none', 'none', 'none', 'block'],
        // Animation value
        visibility: 'hidden'
      }}>
      <FlexWrapper
        sx={{
          alignItems: 'center',
          py: [null, null, null, null, null, '1.41%']
        }}>
        {data.map((item, index) => (
          <React.Fragment key={item.text}>
            <Paragraph
              type="body-100-100"
              variant="bold"
              dangerouslySetInnerHTML={{
                __html: removeBr(item.text)
              }}
              sx={{
                color: 'light',
                textTransform: 'uppercase',
                ':last-of-type': { flex: 1, textAlign: 'right' }
              }}
            />
            {index <= 1 && (
              <Box
                sx={{ bg: 'light', height: '3px', mx: '1.57%', width: '3px' }}
              />
            )}
          </React.Fragment>
        ))}
      </FlexWrapper>
    </GridWrapper>
  );
};
