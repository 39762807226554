const linkDefault = {
  cursor: 'pointer'
};
const buttonDefault = {
  cursor: 'pointer',
  variant: 'typography.buttonPrimary.normal',
  borderRadius: ['9.6px', '11px', '10px', '10px', '10px', '10px'],
  p: [
    '1.04em 1.7em',
    '1em 1.85em',
    '1.08em 1.72em',
    '1.04em 1.54em',
    '1.08em 1.72em',
    '0.94em 1.6em'
  ]
};
const clearButtonDefault = {
  ...buttonDefault,
  display: 'flex',
  alignItems: 'center',
  background: 'transparent',
  p: 0
};

const links = {
  hyperLink: {
    ...linkDefault,
    ':hover,:focus,active': {}
  },
  navigation: {
    ...linkDefault,
    textTransform: 'uppercase',
    variant: 'typography.navlink.normal',
    ':hover,:focus,&.active': {}
  },
  overlayNavigation: {
    ...linkDefault,
    textTransform: 'uppercase',
    variant: 'typography.h2-300-100.normal'
  },
  footer: {
    ...linkDefault,
    variant: 'typography.p-300-100.normal',
    ':hover,:focus,&.active': {}
  }
};

const buttons = {
  primary: {
    ...buttonDefault,
    color: 'light',
    bg: 'primary',
    fontWeight: 'unset',
    textTransform: 'uppercase',
    variant: 'typography.buttonPrimary.normal',
    width: 'max-content'
  },
  secondary: {
    ...buttonDefault,
    color: 'light',
    bg: 'primary',
    borderRadius: '10px',
    p: [
      '1em 1.5em',
      '1em 1.5em',
      '1em 1.5em',
      '1em 0.94em',
      '1.15em 1.72em',
      '1.15em 1.72em'
    ],
    textTransform: 'uppercase',
    variant: 'typography.buttonSecondary.normal'
  },
  callButton: {
    ...buttonDefault,
    color: 'text',
    variant: 'typography.callButton.normal',
    bg: 'secondary',
    p: [
      '0.92em 1.54em 0.92em 1.54em',
      '1.07em 3.33em 1.07em 3.33em',
      '1em 1.5em 1em 1.5em',
      '0.92em 1.54em 0.92em 1.54em',
      '1em 1.5em 1em 1.5em',
      '1em 1.5em 1em 1.5em'
    ]
  },
  clear: {
    ...clearButtonDefault
  },
  menuToggle: {
    ...clearButtonDefault,
    variant: 'typography.h2-300-100.normal',
    justifyContent: 'space-between'
  },
  menuToggleSmall: {
    ...clearButtonDefault,
    variant: 'typography.h3-100-100.normal',
    textTransform: 'uppercase',
    transition: 'all 0.2s ease-out'
  }
};

const cards = {};

export { links, buttons, cards };
