import React from 'react';

// External Components
import { FlexWrapper, Image } from '@thepuzzlers/pieces';

export const Vector = ({ src, designatedRef, ...props }) => {
  return (
    <FlexWrapper ref={designatedRef} {...props}>
      <Image src={src} sx={{ height: '100%', width: '100%' }} />
    </FlexWrapper>
  );
};
