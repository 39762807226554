import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';

export const DividerLine = ({ color, sx }) => (
  <Box
    className="divider-line"
    as="svg"
    sx={{ width: ['100%', '100%', '100%', '88%', '79%', '100%'], ...sx }}
    viewBox="0 0 92 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.185112 3.5C7.97419 4.40455 23.1786 4.17656 31.3351 4.1263C34.9963 3.97554 35.7311 4.00498 40.006 3.97554C48.236 4.0258 56.2454 4.45294 64.4019 4.57858C68.5904 4.65396 73.2932 5.08541 77.4082 4.9849C81.4497 4.90953 87.466 5.0811 91.2871 4.57858C91.7188 4.9849 93.1416 2 90.1178 1C86.2968 0.748736 81.7436 0.92208 77.8491 0.997459C73.6606 1.07284 68.9578 0.641382 64.8428 0.59113C56.6863 0.465498 48.6033 0.0634768 40.4469 0.013224C32.1434 -0.0370288 31.6896 0.0705505 29.1178 0.0929087C20.4469 0.168288 14.1767 0.500003 1.82468 0.5C0.43622 0.5 -0.390409 3 0.185112 3.5Z"
      fill={color}
    />
  </Box>
);
